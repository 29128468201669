import {Dropdown} from "react-bootstrap";
import {useCallback} from "react";
import Komunikat from "../classes/Komunikat";

export default function LoadFromFileButton({setKomunikatyList}) {
    const fileReader = new FileReader();
    fileReader.onloadend = useCallback(() => {
        console.log(fileReader.result);
        setKomunikatyList(Komunikat.deserialize(fileReader.result))
    }, [fileReader, setKomunikatyList]);

    const onSelectFile = useCallback((event) => {
        /** @type HTMLInputElement */
        const element = event.target;

        if (element.files && element.files[0]) {
            console.log(element.files) //todo
            // Jakiś plik został wgrany
            fileReader.readAsText(element.files[0]);
        }
    }, []);

    const onClick = useCallback(() => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "application/json";
        input.onchange = onSelectFile;
        input.multiple = false;
        input.value = "";
        input.click();
    }, []);

    return (
        <Dropdown.Item onClick={onClick}>Wczytaj listę z&nbsp;pliku</Dropdown.Item>
    )
}
