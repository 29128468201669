import {Button} from "react-bootstrap";
import {useCallback} from "react";

/**
 * Zapisuje aktualną listę komunikatów do pliku JSON
 * @param {string} komunikatyMemory Lista komunikatów w pamięci
 * @returns {JSX.Element}
 * @constructor
 */
export default function SaveToFileButton({komunikatyMemory}) {
    const onClick = useCallback(() => {
        const file = new Blob([komunikatyMemory], {type: 'application/json'});
        const url = URL.createObjectURL(file);

        const link = document.createElement("a");
        link.download = "komunikaty.json";
        link.href = url;
        link.target = "_blank";
        link.click();
    }, [komunikatyMemory]);

    return (
        <Button variant={"outline-info"} onClick={onClick}>
            Zapisz listę do pliku
        </Button>
    )
}
