import {Button, ButtonGroup, Dropdown, FloatingLabel, Form, InputGroup, Stack} from "react-bootstrap";
import Kalendarz from "./Kalendarz";
import {PiktogramWTP} from "../classes/PiktogramWTP";
import KalendarzPiktogram from "./KalendarzPiktogram";
import Komunikat from "../classes/Komunikat";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DeleteAllButton from "./DeleteAllButton";
import {decode} from "html-entities";
import SaveToFileButton from "./SaveToFileButton";
import LoadFromFileButton from "./LoadFromFileButton";

/**
 * Formularz dodawania komunikatu
 * @param {function(Komunikat)} addKomunikat
 * @param {function(number, Komunikat)} addKomunikatAt
 * @param {function(number, Komunikat)} updateKomunikat
 * @param {Komunikat[]} komunikatyList
 * @param {function(Komunikat[])} setKomunikatyList
 * @param {function()} clearKomunikatyList
 * @param {string} htmlCode
 * @param {string} komunikatyMemory
 * @param {null|number} editIndex
 * @param {function(null|number)} setEditIndex
 * @returns {JSX.Element}
 * @constructor
 */
export default function GeneratorForm({
                                          addKomunikat,
                                          addKomunikatAt,
                                          updateKomunikat,
                                          komunikatyList,
                                          setKomunikatyList,
                                          clearKomunikatyList,
                                          htmlCode,
                                          komunikatyMemory,
                                          editIndex,
                                          setEditIndex,
                                      }) {
    const refForm = useRef();
    const isEdit = useMemo(() => editIndex !== null, [editIndex]);

    const [nazwa, setNazwa] = useState("");
    const [url, setUrl] = useState("");
    const [data_od, setData_od] = useState();
    const [data_do, setData_do] = useState();
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [isWidth, setIsWidth] = useState(false);
    const [piktogramy, setPiktogramy] = useState(PiktogramWTP.getList());
    const
        defCopyButtonText = "Kopiuj kod HTML do schowka",
        copiedCopyButtonText = "Skopiowano!";
    const [copyButtonText, setCopyButtonText] = useState(defCopyButtonText);

    useEffect(() => {
        // Doszło do zmiany indexu
        if (editIndex !== null) {
            const k = komunikatyList.at(editIndex);
            setNazwa(k.nazwa);
            setUrl(k.url);
            setData_od(k.data_od);
            setData_do(k.data_do);
            setIsHighlighted(k.isHighlighted);
            setIsUpdated(k.isUpdated);
            setIsDraft(k.isDraft);
            setIsWidth(k.isWidth);
            setPiktogramy(PiktogramWTP.getList(k.icons));
        }
    }, [editIndex, komunikatyList]);

    const onReset = useCallback(() => {
        setEditIndex(null);
        setNazwa("");
        setUrl("");
        setData_od(null);
        setData_do(null);
        setIsHighlighted(false);
        setIsUpdated(false);
        setIsDraft(false);
        setIsWidth(false);
        setPiktogramy(PiktogramWTP.getList());
        refForm.current.reset();
    }, [setEditIndex, setNazwa, setUrl, setData_od, setData_do, setIsHighlighted, setIsUpdated, setIsDraft, setIsWidth, setPiktogramy, refForm]);

    const onAddKomunikat = useCallback((at = -1) => {
        const newKomunikat = new Komunikat(nazwa, url, data_od, data_do, isHighlighted, isUpdated, isDraft, isWidth, piktogramy);
        if (isEdit)
            updateKomunikat(editIndex, newKomunikat);
        else {
            if (at === -1)
                addKomunikat(newKomunikat);
            else
                addKomunikatAt(at, newKomunikat);
        }
        onReset(); //Wyczyszczenie formularza po dodaniu danych
    }, [addKomunikat, addKomunikatAt, updateKomunikat, editIndex, isEdit, nazwa, url, data_od, data_do, isHighlighted, isUpdated, isDraft, isWidth, piktogramy, onReset]);

    const onSubmit = useCallback((e) => {
        e.preventDefault(); // Zapobiegamy przeładowaniu strony
        onAddKomunikat();
    }, [onAddKomunikat]);

    const onCopy = useCallback((value) => {
        navigator.clipboard.writeText(value)
            .then(() => {
                setCopyButtonText(copiedCopyButtonText);
                setTimeout(() => setCopyButtonText(defCopyButtonText), 3000);
            });
    }, [setCopyButtonText]);

    const loadList = useCallback(() => {
        console.log(JSON.parse(komunikatyMemory));
        setKomunikatyList(Komunikat.deserialize(komunikatyMemory))
    }, [setKomunikatyList, komunikatyMemory]);

    return (
        <Form ref={refForm} onSubmit={onSubmit} onReset={onReset}>
            <input type={"hidden"} value={editIndex}/>

            <FloatingLabel label={"Tytuł komunikatu"} className={"mb-2"}>
                <Form.Control type={"text"} name={"nazwa"} required={true} autoComplete={"off"}
                              value={nazwa} onChange={e => setNazwa(e.target.value)}/>
            </FloatingLabel>
            <FloatingLabel label={"Adres URL"} className={"mb-2"}>
                <Form.Control type={"text"} name={"url"} autoComplete={"off"}
                              value={url} onChange={e => setUrl(e.target.value)}/>
            </FloatingLabel>
            <Form.Label>Zakres ważności komunikatu</Form.Label>
            <InputGroup className={"mb-2"}>
                <Kalendarz name={"data_od"} required={true} value={data_od} setValue={setData_od}/>
                <InputGroup.Text>–</InputGroup.Text>
                <Kalendarz name={"data_do"} value={data_do} setValue={setData_do}/>
            </InputGroup>
            <Stack direction={"horizontal"} gap={4}>
                <Form.Check
                    type={"switch"}
                    label={"Komunikat wyróżniony"}
                    id={"highlighted"}
                    className={"mb-2"}
                    checked={isHighlighted}
                    onChange={e => setIsHighlighted(e.target.checked)}
                />
                <Form.Check
                    type={"switch"}
                    label={"Komunikat zaktualizowany"}
                    id={"updated"}
                    className={"mb-2"}
                    checked={isUpdated}
                    onChange={e => setIsUpdated(e.target.checked)}
                />
                <Form.Check
                    type={"switch"}
                    label={"Komunikat nieopublikowany (szkic)"}
                    id={"draft"}
                    className={"mb-2"}
                    checked={isDraft}
                    onChange={e => setIsDraft(e.target.checked)}
                />
                <Form.Check
                    type={"switch"}
                    label={"Zwiększona szerokość elementu"}
                    id={"width"}
                    className={"mb-2"}
                    checked={isWidth}
                    onChange={e => setIsWidth(e.target.checked)}
                />
            </Stack>
            <div className={"mb-4"}>
                {PiktogramWTP.getAll().map((p) => (
                    <KalendarzPiktogram key={p.nazwa} piktogram={p}
                                        piktogramList={piktogramy} setPiktogramList={setPiktogramy}/>
                ))}
            </div>
            <ButtonGroup style={{width: "100%"}}>
                <Dropdown as={ButtonGroup}>
                    <Button variant={"success"} type={"submit"}>
                        {isEdit ? "Aktualizuj komunikat" : "Dodaj komunikat"}
                    </Button>
                    {!isEdit &&
                        <>
                            <Dropdown.Toggle split variant="success"/>
                            <Dropdown.Menu>
                                <Dropdown.Item disabled={true}><i>Pozycja komunikatu</i></Dropdown.Item>
                                {
                                    komunikatyList.map((k, i) =>
                                        <Dropdown.Item key={i} onClick={() => onAddKomunikat(i)}>
                                            {decode(k.nazwa)}
                                        </Dropdown.Item>)
                                }
                                <Dropdown.Item onClick={(e) => onSubmit(e)}><i>Ostatnia pozycja</i></Dropdown.Item>
                            </Dropdown.Menu>
                        </>
                    }
                </Dropdown>
                <Button variant={"outline-dark"} type={"reset"}>Wyczyść</Button>
                <DeleteAllButton onReset={onReset} clearKomunikatyList={clearKomunikatyList}/>
                <Button variant={"outline-primary"} onClick={() => onCopy(htmlCode)}>
                    {copyButtonText}
                </Button>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle variant={"outline-success"}>Wczytaj listę</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={loadList}>Wczytaj listę z&nbsp;pamięci</Dropdown.Item>
                        <LoadFromFileButton setKomunikatyList={setKomunikatyList}/>
                    </Dropdown.Menu>
                </Dropdown>
                <SaveToFileButton komunikatyMemory={komunikatyMemory}/>
            </ButtonGroup>
        </Form>
    )
}
