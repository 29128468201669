import {Badge, Button, ButtonGroup, ListGroup, ListGroupItem, Stack} from "react-bootstrap";
import {useCallback, useMemo} from "react";
import {decode} from "html-entities";
import DeleteButton from "./DeleteButton";

/**
 *
 * @param {Komunikat[]} komunikatyList Lista komunikatów
 * @param {function(number)} usunKomunikat Funkcja usuwania komunikatu z określonej pozycji w tabeli
 * @param {function(number, Komunikat)}  replaceKomunikat Funkcja zastępowania komunikatu z określonej pozycji w tabeli
 * @param {function(null|number)} setEditIndex Wprowadza komunikat o określonej pozycji na liście w tryb edycji
 * @returns {JSX.Element}
 * @constructor
 */
export default function KomunikatyList({komunikatyList, usunKomunikat, replaceKomunikat, setEditIndex}) {
    const chPos = useCallback((index, direction) => {
        const tmp = komunikatyList[index];
        const newIndex = index + direction;
        replaceKomunikat(index, komunikatyList[newIndex]);
        replaceKomunikat(newIndex, tmp);
    }, [komunikatyList, replaceKomunikat]);

    const wGore = useCallback((index) => chPos(index, -1), [chPos]);
    const wDol = useCallback((index) => chPos(index, +1), [chPos]);
    const aktualizacja = useCallback((index, komunikat) => {
        komunikat.changeUpdate();
        replaceKomunikat(index, komunikat);
    }, [replaceKomunikat]);
    const szkic = useCallback((index, komunikat) => {
        komunikat.changeDraft();
        replaceKomunikat(index, komunikat);
    }, [replaceKomunikat]);

    const onyOne = useMemo(() => komunikatyList.length === 1, [komunikatyList]);

    return (
        <ListGroup>
            {komunikatyList.map((komunikat, index) => (
                <ListGroupItem key={index} className={komunikat.isHighlighted ? "border border-danger px-2" : "px-2"}>
                    <Stack direction={"horizontal"} gap={1}>
                        {
                            !onyOne &&
                            <ButtonGroup vertical={true} style={{width: "5%"}} size={"sm"}>
                                {index !== 0 &&
                                    <Button variant={"outline-dark"} onClick={() => wGore(index)}>&uarr;</Button>
                                }
                                {index + 1 < komunikatyList.length &&
                                    <Button variant={"outline-dark"} onClick={() => wDol(index)}>&darr;</Button>
                                }
                            </ButtonGroup>
                        }
                        <div style={{width: onyOne ? '95%' : '85%'}} className={"px-2"}>
                            <div className={"mb-2"}>
                                <Stack direction={"horizontal"} gap={1}>
                                    <a href={komunikat.url} target={"_blank"}
                                       className={"h4"}>{decode(komunikat.nazwa)}</a>
                                    {komunikat.isWidth && <Badge bg={"dark"}>SZEROKOŚĆ 100%</Badge>}
                                    {komunikat.isUpdated && <Badge bg={"secondary"}>AKTUALIZACJA</Badge>}
                                    {komunikat.isDraft && <Badge bg={"warning"}>SZKIC</Badge>}
                                    {!komunikat.isUrl && <Badge bg={"danger"}>BRAK ADRESU URL</Badge>}
                                </Stack>
                            </div>
                            <Stack direction={"horizontal"}>
                                <div>
                                    {komunikat.dataOdToString()}–{komunikat.dataDoToString()}
                                </div>
                                <Stack direction={"horizontal"} gap={1} className={"ms-auto"}>
                                    {
                                        komunikat.icons.map((i, ikey) => (
                                            <div key={ikey}>
                                                <img src={i.image} alt={i.alt} className={"piktogram"}/>
                                            </div>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                        </div>
                        <ButtonGroup vertical={true} style={{width: "10%"}} size={"sm"}>
                            <Button variant={"outline-warning"} onClick={() => setEditIndex(index)}>EDYTUJ</Button>
                            <Button variant={"outline-primary"}
                                    onClick={() => aktualizacja(index, komunikat)}>AKTUALIZACJA</Button>
                            {komunikat.isUrl &&
                                <Button variant={komunikat.isDraft ? "outline-success" : "outline-warning"}
                                        onClick={() => szkic(index, komunikat)}>
                                    {komunikat.isDraft ? "PUBLIKACJA" : "SZKIC"}
                                </Button>
                            }
                            <DeleteButton index={index} nazwa={komunikat.nazwa} usunKomunikat={usunKomunikat}/>
                        </ButtonGroup>
                    </Stack>
                </ListGroupItem>
            ))}
        </ListGroup>
    )
}
